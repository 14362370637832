import * as React from 'react'
import { connect } from "react-redux";
import { compose } from 'redux';
import { withRouter,Redirect } from 'react-router-dom';
import Cookies from "universal-cookie";
import parse from 'html-react-parser';
import { analyticsClickEvent } from "../../../action/firebaseAnalytics/firebaseAnalytics";
import { getApplicationImage } from "../../../action/avatar/getAvatar";
import AppleLogin from 'react-apple-login';
import { extractEmail } from '../../../utils/extractEmail';
import { socialLogin }  from "../../../action/auth/socialLogin";
import { appleLogin } from '../../../action/auth/login';
import { frontEndLogs } from '../../../action/logs/frontEndLogs';
import { PropTypes } from 'prop-types';
export class AppleUserLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authResponse:'',
      loginMessage:'',
      userAlreadyExists: false,
      redirect : false,
      showErrorMessage:'',
      load:false
    }
    console.log('ENV_API',process.env.REACT_APP_APPLE_BACKEND_API_URL);
  }


  getHomePageApplicationBannerImg = async() => {
    if(localStorage.getItem('fromWhere') !='skip'){
        const homePageApplicationImg = await getApplicationImage();
        if (homePageApplicationImg && homePageApplicationImg.status === 201 && Array.isArray(homePageApplicationImg.data.image) && homePageApplicationImg.data.image.length !== 0 && homePageApplicationImg.data.image[1] != undefined) {
                let applicationImg = homePageApplicationImg.data.image[1];
                localStorage.setItem('applicationImage', applicationImg);                                   
        }
        else {
          localStorage.setItem('applicationImage', 'images/home-active-contract-bg.png');
        }
     }
  }


  appleuserLogin = async(obj) => {
    console.log('appleuserLogin_social_request',obj);
    const result = obj;
    console.log('appleuser_social_response',result.data.message);
    this.props.appleResponseRecived(false);
    if (result.data.statusCode === 200 || result.data.statusCode === 201) {
      this.handlingAppleAuthSuccessresponse(result)
    }
    else if(result.data.statusCode == 403){
      let errorMessage = result.data.message;
      if (extractEmail(errorMessage) != null) {
          let extractErrorMessage = extractEmail(errorMessage);
          extractErrorMessage.map((src) => {
          let anchorTag = "<a target='_blank' style='color: #ff6200' href='https://mail.google.com/mail/u/0/?fs=1&to=" + src + "&su=Enquiry&tf=cm'>" + src + "</a>"
          errorMessage = errorMessage.replace(src, anchorTag);
          })
      }
      this.setState({showErrorMessage: errorMessage},() => {
        console.log('appleuser_showErrorMessage',this.state.showErrorMessage);
        window.$('#socialMediaErrorModal').modal('show');
        return false;
      })
      
    }else{
      this.showHandlingAppleAuthResponse(result);
    }
                
  }

  handlingAppleAuthSuccessresponse = (result)=> {
    localStorage.setItem('userExists',result.data.data.user_exists);
    localStorage.setItem('UserName', result.data.data.user_details.username);
    localStorage.setItem('email', result.data.data.user_details.email);
    localStorage.setItem('token', result.data.data.access_token);
    localStorage.setItem('sessionId',result.data.data.session_id);
    localStorage.setItem('csrfToken',result.data.data.csrf_token);
    console.log('appleuser_social_response',result.data.message);
    // Set the state whether the user already exists or not
   this.setState({userAlreadyExists: result.data.data.user_exists,showErrorMessage:''},() =>{
    if(!this.state.userAlreadyExists){
      this.getHomePageApplicationBannerImg();
    }
    localStorage.setItem('emailConsent', result?.data?.data?.user_details?.marketing_consent?.email_marketing_consent);
    localStorage.setItem('smsConsent', result?.data?.data?.user_details?.marketing_consent?.sms_marketing_consent);
    localStorage.setItem('mobileConsent', result?.data?.data?.user_details?.marketing_consent?.mobile_app_marketing_consent);

    if(result.data.data.user_details.userid !== '' && 
    result.data.data.user_details.userid  != null && result.data.data.user_details.userid  != undefined){
      localStorage.setItem('userID', result.data.data.user_details.userid);
    }
    this.setState({redirect: true});
   });
  }

  showHandlingAppleAuthResponse = (result)=>{
    this.setState({showErrorMessage: result.message},()=>{
      window.$('#applesocialloginMediaErrorModal').modal('show');
      const urlParams = new URLSearchParams(window.location.search);
      console.log('urlParams',urlParams);
      const codeParam = urlParams.get('code');
      console.log('codeParam',codeParam);

      // Remove the query parameter from the URL
      if (codeParam) {
        this.props.history.push('/');
        const { pathname, search } = this.props.location;
        console.log('uri_pathname',pathname);
        const newSearch = search.replace(`?code=${codeParam}`, '');
        this.props.history.replace({ pathname, search: newSearch });
        console.log('final_uri_pathname',window.location.href);
      }
    });
  }
 

  appleResponse = async response => {
    if(response?.error){
      frontEndLogs('Apple Error Response Received',true,400, 'appleResponse',response?.error);
    }else if (response?.authorization?.code) {
      this.props.appleResponseRecived(true);
      let appleCode = {
        "code": response.authorization.code
      }
      let appleResponse = await appleLogin(appleCode)
    if(appleResponse){
      this.appleuserLogin(appleResponse)
    }
    }
  };

  

  render () {
    const cookies = new Cookies();
    console.log('stateredirect ',this.state.redirect+''+this.state.userAlreadyExists);
    console.log('cookie_retailer',cookies.get('retailerId'));
    if (this.state.redirect) {
      
      if(this.state.userAlreadyExists && cookies.get('retailerId') == undefined){
        return <Redirect push to='/welcome' />;
      }
      if(this.state.userAlreadyExists && cookies.get('retailerId') != undefined){
        return <Redirect push to='/application' />;
      }
      if(!this.state.userAlreadyExists){
        return <Redirect push to={'/application'} />;
      }
    }
    return (
      <div>
      <AppleLogin
    clientId={process.env.REACT_APP_APPLE_IDENTIFIER}
    redirectURI={process.env.REACT_APP_APPLE_REDIRECT_URI}
    usePopup={false} 
    scope="email name"
    responseMode="query"
    callback={this.appleResponse}
    render={renderProps => (  //Custom Apple Sign in Button
              <button
                onClick={renderProps.onClick}
                style={{
                      backgroundColor: "white",
                      border: "0px solid black",
                      fontFamily: "none",
                      lineHeight: "18px",
                      fontSize: "14px"
                    }}
                  >
                    <dev  className="soQr--link" id="google">
                    <img src="images/apple.svg" alt="apple" className='apple-logo-img-fluid'/>
                    </dev>
              </button>
                )}
  />
{this.state.showErrorMessage &&
<div className="modal fade model-error" id="applesocialloginMediaErrorModal" tabIndex="-1" role="dialog" data-keyboard="false" data-backdrop="static" aria-labelledby="applesocialloginMediaErrorLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
  <div className="modal-content">
      
      <div className="modal-body">
          <div className="logo">
              <img src="images/modal-logo.svg" alt="logo" className="apple-popup-img-fluid" />
          </div>
          {/* Added the margin left and right spacing to message displayed in popup */}
          <p className="text-center mt-4 mx-3">{parse(this.state.showErrorMessage)}</p>
      </div>

      <div className="modal-footer mb-4">
          <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={(e) => analyticsClickEvent('Clicked on the Apple user verified response','')}>Ok</button>
      </div>


  </div>
  </div>
</div>
  }
  </div>
    )
    
  }
}

const mapStateToProps = (state) => {
  return {
      FireBaseStore: state.dataStore.FireBaseStore,
      userId: state.dataStore.userId,
  };
  
};


const mapDispatchToProps = (dispatch) => {
  return {
      socialLogin: (formData) => {
          return dispatch(socialLogin(formData));
      },
      setUserId: (userID) => {
        return dispatch({
          type: 'USER_ID',
          value: userID
      });
      }
  }
}

AppleUserLogin.propTypes = {
  appleResponseRecived:PropTypes.bool
}


export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AppleUserLogin)